/* eslint-disable no-unused-vars */
import { useState, useEffect } from 'react';
import { AgentViewModel, StoryDetailView, StoryItemViewModel } from '../../helpers/types';
import APIService from '../../services/services';
import { useParams, useSearchParams } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import StarRatings from 'react-star-ratings';
import Modal from 'react-modal';
import { modalCustomStyles } from '../../helpers/constants';
import AppointmentForm from '../layout/AppointmentForm';
import PhoneForm from '../layout/PhoneForm';
import EmailForm from '../layout/EmailForm';
import { callMe } from '../../helpers/functions';

function BlogArticle() {
  const { id } = useParams();
  const [modalOpen, setModalOpen] = useState<'Email' | 'Phone' | 'Meet' | null>(null);
  const [searchParams] = useSearchParams();
  const [data, setStory] = useState<StoryDetailView>();
  const [loading, setLoading] = useState<boolean>(true);
  const [hideCarrousel, setHideCarrousel] = useState<boolean>(false);
  const [selectedAgent, setSelectedAgent] = useState<AgentViewModel | null>(null);

  const onCall = (agent: AgentViewModel) => {
    setSelectedAgent(agent);
    setModalOpen('Phone');
    callMe(agent);
  };

  const matchedAgentProfile = () => (
    <div className="flex flex-col p-1 bg-gtc6 w-full md:w-[135px] h-full items-center gap-2 text-center mt-3">
      <div className="text-center text-base md:text-xs font-bold p-1">PLAN YOUR NEXT TRIP WITH</div>
      <img
        src={
          data?.matchedAgent.agentFullBio.toLowerCase() === 'unpublished' ? '/images/gtc_stacked_black.png' : data?.matchedAgent.photoUrl
        }
        className="w-[115px] bg-white"
        alt={`Photo of ${data?.matchedAgent.firstName}`}
      />
      <div className="font-bold font-[Avenir-lt-Std] text-xl uppercase">{data?.matchedAgent.firstName}</div>
      {(data?.matchedAgent.rating || 0) > 0 && (
        <StarRatings
          rating={data?.matchedAgent.rating}
          starRatedColor="#ff6d41"
          // changeRating={this.changeRating}
          numberOfStars={5}
          starDimension="16"
          starSpacing="0.5px"
          name="rating"
        />
      )}
      <div className="text-xs">{data?.matchedAgent.agencyName}</div>
      <div className="flex flex-row gap-1 w-full">
        <button
          className={[
            ' w-full h-10 flex justify-center items-center flex-1',
            // eslint-disable-next-line no-constant-condition
            false ? '!cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-black hover:bg-gtc7 '
          ].join(' ')}
          onClick={() => {
            if (data?.matchedAgent) {
              setSelectedAgent(data?.matchedAgent);
              setModalOpen('Email');
            }
          }}
          title={`Email ${data?.matchedAgent.firstName}`}
          aria-label={`Email ${data?.matchedAgent.firstName}`}
          role="button"
          tabIndex={0}>
          <i role="presentation" className="fa-solid fa-envelope text-base text-white"></i>
        </button>
        <button
          className={[
            ' w-full h-10 flex justify-center items-center flex-1',
            // eslint-disable-next-line no-constant-condition
            false ? '!cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-black hover:bg-gtc7 '
          ].join(' ')}
          onClick={() => {
            if (data?.matchedAgent) {
              setModalOpen('Phone');
              setSelectedAgent(data?.matchedAgent);
              callMe(data?.matchedAgent);
            }
          }}
          title={`Call ${data?.matchedAgent.firstName}`}
          aria-label={`Call ${data?.matchedAgent.firstName}`}
          role="button"
          tabIndex={0}>
          <i role="presentation" className="fa-solid fa-phone text-base text-white"></i>
        </button>
      </div>
      {data?.matchedAgent.agentFullBio.toLowerCase() !== 'unpublished' && (
        <a
          href={data?.matchedAgent.detailsUrl}
          className={[
            ' w-full h-12 flex justify-center items-center py-3 text-xs text-white',
            // eslint-disable-next-line no-constant-condition
            false ? '!cursor-not-allowed bg-gray-400' : 'cursor-pointer bg-black hover:bg-gtc7 '
          ].join(' ')}
          title={`View ${data?.matchedAgent.firstName}`}>
          VIEW BIO
        </a>
      )}
    </div>
  );

  const getStory = () => {
    if (id) {
      const preview = searchParams.get('preview') === 'true';
      const agentId = searchParams.get('agentId');
      setLoading(true);
      APIService()
        .gtcService.fetchStory(parseInt(id), parseInt(agentId || '0'), preview)
        .then((res) => {
          setLoading(false);
          if (res.responseStatus === 1) {
            setStory(res.data);
          } else {
            setStory(undefined);
          }
        });
    }
  };

  const breadCrumb = () => (
    <div className="text-base text-black gap-1 bg-gtc8 p-1.5">
      <a href="/" className="pr-1">
        Home {'>'}
      </a>
      <a href="/advisor-inspiration" className="pr-1">
        Advisor Inspiration {'>'}
      </a>
      <div className="text-gtc9 pr-1 inline">{data?.story.title}</div>
    </div>
  );

  useEffect(() => {
    Modal.setAppElement('#root');
    getStory();
  }, []);

  useEffect(() => {
    const returnAgents = searchParams.get('returnAgents') === '0';
    if (returnAgents) {
      setHideCarrousel(true);
    } else {
      setHideCarrousel(false);
    }
  }, []);

  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/pure-min.css" />
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/pure/0.6.0/grids-responsive-min.css" />
        <meta name="title" content={`${data?.story.title} | Global Travel Collection`} />
        <meta name="description" content={data?.story?.shortDescription.slice(0, 180)} />
        <title>{data?.story.title || ''} | Global Travel Collection</title>
      </Helmet>
      {loading && (
        <div className="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-gray-700 opacity-75 flex flex-col items-center justify-center">
          <div className="loader ease-linear rounded-full border-4 border-t-4  h-12 w-12 mb-4"></div>
        </div>
      )}
      {data && (
        <div className="">
          {breadCrumb()}
          <section id="mainsec" className="w-full">
            <div
              className="large-hero justify-center px-4 items-center flex"
              role="img"
              aria-label="hero img"
              style={{
                backgroundImage: `url(${data.story.heroImageUrl})`,
                backgroundSize: 'cover',
                boxShadow: 'inset 0 0 0 2000px rgba(0,0,0,0.2)'
              }}>
              <div className="flex flex-col items-center 2xl:container">
                <div>
                  <h1 className="text-2xl sm:text-65xl text-gtc4 text-center font-normal font-[Avenir-lt-Std]">{data.story.title}</h1>
                </div>
              </div>
            </div>
          </section>

          <article className="p-2 lg:py-4 lg:px-4 mx-auto bg-white 2xl:container">
            <section className="mx-2 md:mx-20">
              <div className="flex flex-col-reverse md:flex-row gap-4 md:gap-10 mt-4 ">
                <div className="w-full">
                  <section className="flex flex-col sm:flex-row gap-4 lg:items-center">
                    {/* <div className="w-full text-xl md:text-25xl">{data.story.shortDescription}</div> */}
                    <div className="flex-1  tracking-wider leading-9 text-xl md:text-lg font-light">
                      <div className="w-full">
                        <div className="mt-6 md:mt-8 float-left text-[80px] md:text-[100px]"> {data.story.shortDescription.charAt(0)}</div>
                        <p className=" font-normal font-[Avenir-lt-Std]"> {data.story.shortDescription.substring(1)}</p>
                      </div>
                    </div>
                    <div className="order-first sm:order-last">
                      <img
                        src={data.story.thumbImageUrl}
                        alt={data.story.thumbImageDescription}
                        className="blog-summary-thumbnail-image mx-auto h-[310px] w-[205px] object-cover"
                      />
                    </div>
                  </section>

                  <section className="divBlogStory" dangerouslySetInnerHTML={{ __html: data.story.storyBody }} />
                </div>
                {data.matchedAgent !== null && <>{matchedAgentProfile()}</>}
              </div>

              {data.relatedAgents.length > 0 && !hideCarrousel && (
                <section className="mt-4">
                  <h2 className="uppercase mb-4 font-[Avenir-lt-Std] font-normal tex md:text-[28px]">
                    Ready to go? These travel experts can help you plan a trip related to this article
                  </h2>

                  <ul className="flex flex-col gap-4 lg:flex-row">
                    {data.relatedAgents.map((agent) => (
                      <li className="p-3 bg-gtc4 flex-1" key={agent.id}>
                        <div className="flex flex-col items-center gap-2 sm:flex-row">
                          <div className="max-w-sm sm:w-40">
                            <a href={agent.detailsUrl}>
                              <img
                                src={agent.notEmptyPhotoUrl}
                                alt={`${agent.fullName} ${agent.city}, ${agent.state} Luxury Travel Agent`}
                                width={350}
                                height={390}
                              />
                            </a>
                          </div>
                          <div>
                            <p className="text-xl font-semibold uppercase">
                              <a href={agent.detailsUrl}>{agent.fullName}</a>
                            </p>
                            <p className="text-base uppercase">{agent.title}</p>
                            <p className="text-sm uppercase">{agent.location}</p>
                            <p className="text-xs">{agent.destinations.join(',')}</p>
                          </div>
                        </div>
                        <div className="flex flex-col items-center justify-between gap-2 sm:flex-row">
                          <div>
                            {agent.reviewsCount > 0 && (
                              <div className="flex flex-col md:flex-row gap-2 items-center">
                                <div>
                                  {' '}
                                  <StarRatings
                                    rating={agent.rating}
                                    starRatedColor="#ff6d41"
                                    // changeRating={this.changeRating}
                                    numberOfStars={5}
                                    starDimension="14"
                                    starSpacing="2px"
                                    name="rating"
                                  />
                                </div>
                                <div className="text-sm">({agent.reviewsCount} Reviews)</div>
                              </div>
                            )}
                          </div>
                          <div className="flex flex-row gap-2 w-full sm:w-auto">
                            <div className="w-1/2 sm:w-auto">
                              <div
                                className="bg-gtc7 hover:bg-black md:w-20 h-12 flex justify-center items-center cursor-pointer"
                                title={`Email ${agent.fullName}`}
                                onClick={() => {
                                  setSelectedAgent(agent);
                                  setModalOpen('Email');
                                }}
                                aria-label={`Email ${agent.fullName}`}
                                role="button"
                                tabIndex={0}>
                                <i role="presentation" className="fa-solid fa-envelope fa-2xl text-white"></i>
                              </div>
                            </div>
                            <div className="w-1/2 sm:w-auto">
                              <div
                                className="bg-gtc7 hover:bg-black md:w-20 h-12 flex justify-center items-center cursor-pointer"
                                title={`Call ${agent.fullName}`}
                                onClick={() => onCall(agent)}
                                aria-label={`Call ${agent.fullName}`}
                                role="button"
                                tabIndex={0}>
                                <i role="presentation" className="fa-solid fa-phone fa-2xl text-white"></i>
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-10 pb-4 flex sm:justify-center">
                    <a
                      href="/find-an-elite-travel-agent"
                      className="w-full sm:w-72 text-center py-2 font-normal text-white bg-black border border-black hover:font-semibold px-7 hover:cursor-pointer">
                      Find Other Agents
                    </a>
                  </div>
                </section>
              )}
              {data.story.relatedStories.length > 0 && (
                <section className="mt-4">
                  <h2 className="uppercase mb-4">Found 4 Stories</h2>
                  <ul className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4 w-full">
                    {data.story.relatedStories.map((story) => (
                      <li key={story.id}>
                        <div className="relative">
                          <a title={story.title} href={story.url}>
                            <img className="w-full" src={story.thumbnailImage} alt={story.title} width={410} height={615} />
                          </a>
                          <div className="absolute bottom-0 left-0 right-0 px-4 py-2 text-center bg-black">
                            <p className="mb-0 text-xs md:text-sm lg:text-base text-white uppercase">{story.title}</p>
                          </div>
                        </div>
                        <div className="mt-2">{story.shortDescription}</div>
                        <a title={story.title} href={story.url}>
                          <div className="mt-2 flex flex-row items-center gap-1 font-bold">
                            Read More
                            <i role="presentation" className="fa-solid fa-arrow-right"></i>
                          </div>
                        </a>
                      </li>
                    ))}
                  </ul>
                  <div className="mt-10 pb-4 flex sm:justify-center">
                    <a
                      href="/advisor-inspiration"
                      className="w-full sm:w-72 text-center py-2 font-normal text-white bg-black border border-black hover:font-semibold px-7 hover:cursor-pointer">
                      See More Inspiration Stories
                    </a>
                  </div>
                </section>
              )}
            </section>
          </article>
        </div>
      )}

      <Modal isOpen={modalOpen !== null} onRequestClose={() => setModalOpen(null)} style={modalCustomStyles}>
        <div className="z-50 ">
          <div className="flex justify-end cursor-pointer" onClick={() => setModalOpen(null)}>
            <i role="presentation" className="fa-solid fa-times text-black hover:text-gray-600"></i>
          </div>
          {selectedAgent && (
            <>
              {modalOpen === 'Meet' && <AppointmentForm agent={{ ...selectedAgent, agentAppointmentAvailabilty: [] }} />}
              {modalOpen === 'Phone' && <PhoneForm agent={selectedAgent} callback={() => setModalOpen('Email')} />}
              {modalOpen === 'Email' && <EmailForm agent={selectedAgent} />}
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default BlogArticle;
